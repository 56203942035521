
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "Splash",
  components: {},
  data() {
    return {
      initialized: false,
    };
  },
  created() {
    this.$store
      .dispatch(Actions.InitPublic, this.$route.params.token)
      .then(() => (this.initialized = true));
  },
  computed: {},
  methods: {},
});
